import React from "react"
import { graphql } from "gatsby"

export const CoreParagraphBlock = graphql`
  fragment CoreParagraphBlock on WordPress_CoreParagraphBlock {
    name
    attributes {
      ... on WordPress_CoreParagraphBlockAttributes {
        align
        content
      }
    }
  }
`

export const CoreParagraph = ({ data, className }) => {
  return <p className={`${className}`} dangerouslySetInnerHTML={{ __html: data.attributes.content }} />
}
