export const getDate = (dateInput, lang, options = { time: true }) => {
  const hari = ['Minggu', 'Senin', 'Selasa', 'Rabu', 'Kamis', "Jum'at", 'Sabtu']
  const bulan = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  const date = new Date(dateInput)

  if (!options.time) {
    if (lang === "ID") {
      const m = bulan[date.getMonth()]
      return `${date.getDate()} ${m} ${date.getFullYear()}`
    }
    const m = months[date.getMonth()]
    return `${m} ${date.getDate()} ${date.getFullYear()}`
  }
  if (lang === "ID") {
    const d = hari[date.getDay()]
    const m = bulan[date.getMonth()]
    const h = `${date.getHours() < 10 ? '0' : ''}${date.getHours()}`
    const min = `${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}`
    return `${d}, ${date.getDate()} ${m} ${date.getFullYear()} pukul ${h}:${min}`
  }
  const d = days[date.getDay()]
  const m = months[date.getMonth()]
  const time = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
  return `${d}, ${m} ${date.getDate()}, ${date.getFullYear()} ${time}`
}