import React, { useLayoutEffect, useContext, useEffect } from "react"
import ReactMarkdown from "react-markdown"
import Img from "gatsby-image"
import moment from "moment"
import Helmet from "react-helmet"

import { LangContext } from "../context/LangContext"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, Container } from "../components/anti/grid/grid"
import { CoreHeading } from "../components/gutenberg/core-heading"
import { CoreParagraph } from "../components/gutenberg/core-paragraph"
import { PostHeader } from "../components/anti/post/post"
import { replaceUnicode } from "../components/anti/utils/utils"
import { Breadcrumb } from "../components/anti/breadcrumb/breadcrumb"
import { SocialShare } from "../components/anti/social-links/social-links"
import { Cover } from "../components/anti/cover/cover"
import { Card, CardAmp } from "../components/anti/card/card"
import { AcfGallery } from "../components/gutenberg/acf-gallery"
import { getDate } from "../utils/get-date"

const NewsAmpDetails = ({ location, pageContext }) => {
  const { lang, setLang } = useContext(LangContext)
  const wp =
    lang == "EN"
      ? pageContext.data.blocks
      : lang == "ID" && pageContext.data.translation !== null
        ? pageContext.data.translation.blocks
        : lang == "ID" && pageContext.data.translation == null
          ? pageContext.data.blocks
          : pageContext.data.blocks
  const coverMain =
    wp[0] !== undefined && wp[0].name === "acf/cover-main" ? wp[0] : false
  const currentCategory = pageContext.data.categories.nodes[0].slug
  const seo = pageContext.langKey === "en"
    ? pageContext.data
    : pageContext.data.translation
    || pageContext.data
  const news = pageContext.news
  let recommendedNews =
    news &&
    news.reduce((result, currentValue) => {
      if (
        currentValue.categories &&
        currentValue.categories.nodes[0].slug === currentCategory &&
        currentValue.slug !== pageContext.data.slug
      ) {
        result.push(currentValue)
      }
      return result
    }, [])

  const langKey = (location?.pathname?.split('/')[1] || location?.uri?.split('/')[1]) === "id" ? "ID" : lang === "ID" ? "ID" : "EN"
  const redirectAmpHtml = process.env.BASE_URL + location.pathname

  let gtagParam = {
    "vars" : {
      "gtag_id":"UA-96661604-1",
      "config": {
        "UA-96661604-1": {
          "page_location": redirectAmpHtml
        }
      }
    },
    "triggers": {
      "trackPageview": {
        "on": "visible",
        "request": "pageview"
      }
    }
  }

  const handleCopy = () => {
    const thisInput = document.createElement("INPUT")

    document.body.append(thisInput)
    thisInput.value = location.href
    thisInput.select()
    document.execCommand("copy")
    thisInput.remove()
    setTimeout(function(){document.getElementById('copyButton').innerHTML = `<i class="fa fa-link"></i> <span class="tooltiptext">${langKey === "EN" ? "URL has copied" : "Tautan telah disalin"}</span>`;}, 200)
    setTimeout(function(){document.getElementById('copyButton').innerHTML = '<i class="fa fa-link"></i>';}, 2000)
  }

  useEffect(() => { setLang(langKey) }, [langKey])

  useLayoutEffect(() => {
    const navbar = document.querySelector(".navbar")
    navbar.classList.remove("transparent")
    navbar.classList.replace("dark", "light")
  }, [])

  // const SEODefaultImg = pageContext.langKey === "en"
  //   ? pageContext?.data?.blocks[0]?.coverMain?.img?.mediaItemUrl
  //   : pageContext?.data?.translation?.blocks[0]?.coverMain?.img?.mediaItemUrl
  //   || pageContext?.data?.blocks[0]?.coverMain?.img?.mediaItemUrl

  const SEOFeaturedImg = pageContext.langKey === "en"
    ? pageContext.data.featuredImage?.sourceUrl
    : pageContext.data.translation?.featuredImage?.sourceUrl
    || pageContext.data.featuredImage?.sourceUrl

  return (
    <Layout
      newsSlug={
        pageContext.langKey === "en"
          ? pageContext.data.translation?.slug || pageContext.data.slug
          : pageContext.data.slug
      }
      isAmp={true}
      isGtag={false}
    >
      <Helmet>
        <html amp="" />
        <link rel="canonical" href={redirectAmpHtml} />
        <style amp-boilerplate="">
          {`
            body {
              -webkit-animation: -amp-start 8s steps(1, end) 0s 1 normal both;
              -moz-animation: -amp-start 8s steps(1, end) 0s 1 normal both;
              -ms-animation: -amp-start 8s steps(1, end) 0s 1 normal both;
              animation: -amp-start 8s steps(1, end) 0s 1 normal both;
            }
            @-webkit-keyframes -amp-start {
              from {
                visibility: hidden;
              }
              to {
                visibility: visible;
              }
            }
            @-moz-keyframes -amp-start {
              from {
                visibility: hidden;
              }
              to {
                visibility: visible;
              }
            }
            @-ms-keyframes -amp-start {
              from {
                visibility: hidden;
              }
              to {
                visibility: visible;
              }
            }
            @-o-keyframes -amp-start {
              from {
                visibility: hidden;
              }
              to {
                visibility: visible;
              }
            }
            @keyframes -amp-start {
              from {
                visibility: hidden;
              }
              to {
                visibility: visible;
              }
            }
          `}
        </style>
        <noscript>
          {`
            <style amp-boilerplate="">
              body {
                -webkit-animation: none;
                -moz-animation: none;
                -ms-animation: none;
                animation: none;
              }
            </style>
          `}
        </noscript>
        <style type="text/css" amp-custom="">
          {`
            /* FontFamily */
              /* cyrillic-ext */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 300;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
                unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
              }
              /* cyrillic */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 300;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format('woff2');
                unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
              }
              /* greek-ext */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 300;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format('woff2');
                unicode-range: U+1F00-1FFF;
              }
              /* greek */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 300;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format('woff2');
                unicode-range: U+0370-03FF;
              }
              /* hebrew */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 300;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format('woff2');
                unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
              }
              /* vietnamese */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 300;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format('woff2');
                unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
              }
              /* latin-ext */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 300;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2');
                unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
              }
              /* latin */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 300;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
                unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
              }
              /* cyrillic-ext */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
                unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
              }
              /* cyrillic */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format('woff2');
                unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
              }
              /* greek-ext */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format('woff2');
                unicode-range: U+1F00-1FFF;
              }
              /* greek */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format('woff2');
                unicode-range: U+0370-03FF;
              }
              /* hebrew */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format('woff2');
                unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
              }
              /* vietnamese */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format('woff2');
                unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
              }
              /* latin-ext */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2');
                unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
              }
              /* latin */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
                unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
              }
              /* cyrillic-ext */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 500;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
                unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
              }
              /* cyrillic */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 500;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format('woff2');
                unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
              }
              /* greek-ext */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 500;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format('woff2');
                unicode-range: U+1F00-1FFF;
              }
              /* greek */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 500;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format('woff2');
                unicode-range: U+0370-03FF;
              }
              /* hebrew */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 500;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format('woff2');
                unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
              }
              /* vietnamese */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 500;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format('woff2');
                unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
              }
              /* latin-ext */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 500;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2');
                unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
              }
              /* latin */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 500;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
                unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
              }
              /* cyrillic-ext */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
                unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
              }
              /* cyrillic */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format('woff2');
                unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
              }
              /* greek-ext */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format('woff2');
                unicode-range: U+1F00-1FFF;
              }
              /* greek */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format('woff2');
                unicode-range: U+0370-03FF;
              }
              /* hebrew */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format('woff2');
                unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
              }
              /* vietnamese */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format('woff2');
                unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
              }
              /* latin-ext */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2');
                unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
              }
              /* latin */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
                unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
              }
              /* cyrillic-ext */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
                unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
              }
              /* cyrillic */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format('woff2');
                unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
              }
              /* greek-ext */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format('woff2');
                unicode-range: U+1F00-1FFF;
              }
              /* greek */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format('woff2');
                unicode-range: U+0370-03FF;
              }
              /* hebrew */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format('woff2');
                unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
              }
              /* vietnamese */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format('woff2');
                unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
              }
              /* latin-ext */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2');
                unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
              }
              /* latin */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
                unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
              }
              /* cyrillic-ext */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 800;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSKmu1aB.woff2) format('woff2');
                unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
              }
              /* cyrillic */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 800;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSumu1aB.woff2) format('woff2');
                unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
              }
              /* greek-ext */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 800;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSOmu1aB.woff2) format('woff2');
                unicode-range: U+1F00-1FFF;
              }
              /* greek */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 800;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSymu1aB.woff2) format('woff2');
                unicode-range: U+0370-03FF;
              }
              /* hebrew */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 800;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS2mu1aB.woff2) format('woff2');
                unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
              }
              /* vietnamese */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 800;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSCmu1aB.woff2) format('woff2');
                unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
              }
              /* latin-ext */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 800;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTSGmu1aB.woff2) format('woff2');
                unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
              }
              /* latin */
              @font-face {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 800;
                font-stretch: 100%;
                font-display: swap;
                src: url(https://fonts.gstatic.com/s/opensans/v34/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-muw.woff2) format('woff2');
                unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
              }
            /* FontFamily */

            body, footer {
              font-family: 'Open Sans';
              font-size: .9rem;
              font-weight: 400;
              line-height: 1.5;
              color: #333;
              text-align: left;
            }
            .toggle-dual-languages{
              display: flex;
              border-radius: 0.25rem;
              margin-top: 1rem;
            }
            .toggle-dual-languages .toggle-item {
              background: #c8ced5;
              padding: 7px;
              cursor: pointer;
              transition: .4s;
            }
            .toggle-dual-languages .toggle-item:first-child {
              border-top-left-radius: 0.25rem;
              border-bottom-left-radius: 0.25rem;
            }
            .toggle-dual-languages .toggle-item:nth-child(2) {
              border-top-right-radius: 0.25rem;
              border-bottom-right-radius: 0.25rem;
              border-right: none;
            }
            .toggle-dual-languages .toggle-item p {
              font-size: 1rem;
              text-transform: uppercase;
              margin: 0px;
              color: #fff;
              position: relative;
              z-index: 2;
            }
            .toggle-dual-languages .toggle-item.active{
              position: relative;
              cursor: default;
            }
            .toggle-dual-languages .toggle-item.active:before {
              content: "";
              height: 100%;
              position: absolute;
              width: 100%;
              top: 0;
              left: 0;
              background: #f79226;
              z-index: 1;
              border-radius: 0.25rem;
              transition: all .2s cubic-bezier(.55,.085,.68,.53);
              box-shadow: 0 0.15rem 0.5rem rgb(0 0 1 / 8%);
            }
            .text-gray-400 {
              color: #9ca7b2;
            }
            .footer-facebook-icon,
            .footer-twitter-icon,
            .footer-instagram-icon,
            .footer-youtube-icon,
            .footer-tiktok-icon{
              fill: currentColor;
            }

            @media (max-width: 320.98px) {

            }
            @media (min-width: 321px) and (max-width: 991.98px) {
              /* general */
                .amp-sidebar-mask{
                  z-index: 1;
                }
                .col-12 {
                  flex: 0 0 100%;
                  max-width: 100%;
                }
                .col-md-2, .col-md-4, .col-md-6, .col-md-12{
                  position: relative;
                  width: 100%;
                  padding-right: 10px;
                  padding-left: 10px;
                }
                a {
                  color: #f79226;
                  background-color: transparent;
                  text-decoration: none;
                }

              /* header */
                .navbar-wrapper.fixed-top {
                  position: fixed;
                  right: 0;
                  left: 0;
                  top: 0;
                  z-index: 1030;
                  width: 100%;
                }
                .navbar-wrapper.fixed-top header{
                  position: fixed;
                  width: 100%;
                }
                .navbar-wrapper.fixed-top header .navbar.navbar-expand-lg{
                  height: 60px;
                  max-width: 100%;
                }
                .navbar-wrapper.fixed-top header .navbar.navbar-expand-lg ul{
                  padding: 1rem 1.5rem;
                  max-width: 100%;
                }
                .navbar-wrapper.fixed-top header .navbar.navbar-expand-lg ul li.navbar-toggler,
                .navbar-wrapper.fixed-top header .navbar.navbar-expand-lg ul li.login-toggler{
                  float: right;
                }
                .navbar-wrapper.fixed-top header .navbar.navbar-expand-lg ul li .btn-login{
                  text-decoration: none;
                  right: 50px;
                  padding: 5px 15px;
                  margin-top: 10px;
                  color: #fff;
                  background-color: #1e2d80;
                  border-color: #1e2d80;
                  display: inline;
                  text-align: center;
                  font-weight: 400;
                  vertical-align: middle;
                  margin-right: 5px;
                }
                .navbar-wrapper.fixed-top amp-sidebar{
                  width: 100%;
                  flex-direction: column;
                  background-color: #fff;
                  max-width: 100vw;
                }
                .navbar-wrapper.fixed-top amp-sidebar .close-sidebar-right{
                  padding: 15px;
                  text-align: right;
                  cursor: pointer;
                }
                .navbar-wrapper.fixed-top amp-sidebar .close-sidebar-left{
                  padding: 15px;
                  text-align: left;
                  cursor: pointer;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.language-wrapper {
                  float: right;
                  margin-top: 0px;
                  padding-left: 0px;
                  padding-right: 20px;
                  list-style: none;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.social-links {
                  position: relative;
                  display: flex;
                  padding-left: 20px;
                  width: 50%;
                  float: left;
                  list-style: none;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.social-links li{
                  float: left;
                  width: 40px;
                  margin-right: 5px;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.social-links li a.footer-youtube-icon{
                  height: 35px;
                  width: 35px;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.social-links li a{
                  color: #1e2d80;
                  height: 30px;
                  width: 30px;
                  display: flex;
                  align-items: center;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.sidebar,
                .navbar-wrapper.fixed-top amp-sidebar ul.sidebar-additional {
                  padding: 15px;
                  list-style-type: none;
                  display: flex;
                  flex-direction: column;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.sidebar-additional li{
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                  left: 0;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.sidebar li a,
                .navbar-wrapper.fixed-top amp-sidebar ul.sidebar li .sub-sidebar {
                  margin-bottom: 0.5rem;
                  line-height: 1.2;
                  cursor: pointer;
                  display: inline-block;
                  text-decoration: none;
                  padding: 0.5rem 0rem;
                  font-size: 1.35rem;
                  font-weight: 600;
                  color: #1e2d80;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.sidebar li.social-links-wrapper{
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                  left: 0;
                  padding-left: 1rem;
                  padding-right: 1rem;
                  bottom: 2rem;
                  overflow: hidden;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.sidebar li.social-links-wrapper ul.social-links{
                  display: block;
                  margin: 0 0 0 -0.125rem;
                  margin-top: 1rem;
                  padding-left: 0;
                  list-style: none;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.sidebar li.social-links-wrapper ul.social-links li.social-item{
                  margin-right: 0.55rem;
                  display: inline-block;
                }                
                .navbar-wrapper.fixed-top amp-sidebar ul.navbar-nav-developments,
                .navbar-wrapper.fixed-top amp-sidebar ul.navbar-nav-about,
                .navbar-wrapper.fixed-top amp-sidebar ul.navbar-nav-services {
                  display: block;
                  padding: 0px 20px;
                  flex-wrap: wrap;
                  align-items: center;
                  justify-content: space-between;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.navbar-nav-developments li {
                  list-style: none;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.navbar-nav-developments li h2 {
                  margin-bottom: 0.5rem;
                  font-weight: 700;
                  line-height: 1.2;
                  color: #1e2d80;
                  margin-top: 0px;
                  padding-left: 0px;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.navbar-nav-developments li span {
                  margin-top: 0;
                  margin-bottom: 1rem;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.navbar-nav-developments li.nav-item {
                  flex-direction: column;
                  display: flex;
                  padding-left: 0;
                  margin-bottom: 1rem;
                  list-style: none;
                  overflow: hidden;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.submenu-list-card{
                  padding: 0px 20px;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.navbar-nav-about.mb-3 li.nav-item,
                .navbar-wrapper.fixed-top amp-sidebar ul.navbar-nav-services.mb-3 li.nav-item {
                  list-style: none;
                  padding: 10px 0px;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.navbar-nav-developments li.nav-item a.nav-link,
                .navbar-wrapper.fixed-top amp-sidebar ul.navbar-nav-about li a.nav-link,
                .navbar-wrapper.fixed-top amp-sidebar ul.navbar-nav-services li a.nav-link {
                  font-size: .9rem;
                  padding-left: 0;
                  padding-right: 0;
                  color: #1e2d80;
                  font-weight: 700;
                  position: relative;
                }
                .navbar-wrapper.fixed-top amp-sidebar  h5{
                  color: #000001;
                  padding: 0px 20px;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.about-submenu {
                  list-style: none;
                  padding-inline-start: 40px;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.about-submenu li.about-submenu-item {
                  font-size: 1rem;
                  padding: 0.75rem 0;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.about-submenu li.about-submenu-item a {
                  color: #677685;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.contact-us-wrapper {
                  list-style: none;
                  padding: 0px;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.contact-us-wrapper li{
                  list-style: none;
                  padding: 0px 20px;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.sidebar li a.btn-secondary,
                .navbar-wrapper.fixed-top amp-sidebar button.btn-secondary,
                .navbar-wrapper.fixed-top amp-sidebar ul.navbar-nav-services li button.btn-secondary,
                .navbar-wrapper.fixed-top amp-sidebar ul.sidebar-additional li a.btn-secondary{
                  cursor: pointer;
                  text-transform: capitalize;
                  display: block;
                  width: 100%;
                  color: #fff;
                  background-color: #1e2d80;
                  border-color: #1e2d80;
                  font-weight: 400;
                  text-align: center;
                  vertical-align: middle;
                  border: 1px solid transparent;
                  padding: 0.75rem 1.5rem;
                  font-size: .9rem;
                  line-height: 1.5;
                }
                .navbar-wrapper.fixed-top amp-sidebar h2 {
                  margin-bottom: 0.5rem;
                  font-weight: 700;
                  line-height: 1.2;
                  color: #1e2d80;
                  margin-top: 0px;
                  padding: 0px 20px;
                }

              /* footer */
                footer{
                  display: block;
                  background: #f3f3f3;
                }
                footer .footer-top.py-main.pb-4{
                  position: relative;
                  padding-top: 1.75rem;
                  padding-bottom: 1.75rem;
                }
                footer .footer-top.py-main.pb-4 .container{
                  padding-left: 20px;
                  padding-right: 20px;
                  max-width: 1140px;
                  margin-right: auto;
                  margin-left: auto;
                }
                footer .footer-top.py-main.pb-4 .container .row{
                  display: flex;
                  width: 100%;
                  margin-right: -10px;
                  margin-left: -10px;
                  flex-flow: wrap;
                }
                footer .footer-top .footer-col h5{
                  font-size: 1.125rem;
                  font-weight: 700;
                  text-transform: uppercase;
                  margin-bottom: 1.25rem;
                  line-height: 1.2;
                  color: #1e2d80;
                }
                footer .footer-top.py-main.pb-4 .container .row .col-md-2.col-12.mb-3,
                footer .footer-top.py-main.pb-4 .container .row .col-md-4.col-12.mb-3{
                  margin-bottom: 1rem;
                  position: relative;
                  padding-right: 10px;
                  padding-left: 10px;
                }
                footer .footer-top.py-main.pb-4 .container .row .col-md-2.col-12.mb-3 ul.footer-nav{
                  list-style: none;
                  padding-left: 1px;
                }
                footer .footer-top.py-main.pb-4 .container .row .col-md-2.col-12.mb-3 ul.footer-nav li.footer-item{ 
                  padding: 10px 0px;
                }
                footer .footer-top.py-main.pb-4 .container .row .col-md-4.col-12.mb-3 .footer-col.footer-newsletter ul.social-links.mt-3{
                  display: block;
                  margin: 0 0 0 -0.125rem;
                  margin-top: 1rem;
                  padding-left: 0;
                  list-style: none;
                }
                footer .footer-top.py-main.pb-4 .container .row .col-md-4.col-12.mb-3 .footer-col.footer-newsletter ul.social-links.mt-3 .social-item{
                  margin-right: 0.55rem;
                  display: inline-block;
                }
                footer .footer-top.py-main.pb-4 .container .row .col-md-4.col-12.mb-3 .footer-col.footer-newsletter ul.social-links.mt-3 .social-item .social-link{
                  height: 25px;
                  width: 25px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: #1e2d80;
                }
                footer .footer-top.py-main.pb-4 .container .row .col-md-4.col-12.mb-3 .footer-col.footer-newsletter ul.social-links.mt-3 .social-item .social-link.footer-youtube-icon{
                  height: 30px;
                  width: 35px;
                }
                footer .footer-top.py-main.pb-4 .container .row .col-md-4.col-12.mb-3 .footer-col.footer-newsletter ul.social-links.mt-3 .social-item .social-link.footer-tiktok-icon{
                  height: 22px;
                  width: 22px;
                }
                footer .footer-top.py-main.pb-4 .container .row .col-md-4.col-12.mb-3 .footer-col.footer-newsletter .footer-copyright.d-none.d-md-block{
                  display: none;
                }
                footer .footer-top.py-main.pb-4 .container .row .col-md-4.col-12.mb-3 .footer-col.footer-newsletter .footer-copyright.d-none.d-md-block a{
                  color: #333;
                  font-weight: 600;
                }
                
                footer .footer-top.py-main.pb-4 .container .row .col-md-12 .row .col-md-4 .footer-copyright.d-block.d-md-none{
                  display: block;
                }
                footer .footer-top .footer-copyright a{
                  margin-top: 1.5rem;
                  font-size: .7875rem;
                  color: #000001;
                }
                footer .red-ribbon{
                  display: flex;
                  justify-content: flex-end;
                  position: relative;
                  margin-top: -16px;
                  width: 100%;
                }
                footer .red-ribbon amp-img{
                  z-index: 3;
                  margin-bottom: 16px;
                }
                footer .red-ribbon .ribbon {
                  height: 68px;
                  position: absolute;
                  bottom: 0;
                }
                footer .red-ribbon .ribbon.red {
                  z-index: 3;
                  left: 0;
                  background: #dd1b20;
                  height: 63px;
                  padding: 7px 0px;
                  width: calc(100% - 199px);
                }
                footer .red-ribbon .ribbon.black {
                  width: 247px;
                  right: 0;
                  z-index: 2;
                  bottom: 0;
                  background: #2a2931;
                }
                footer .red-ribbon .ribbon.black-bottom{
                  z-index: 3;
                  left: 0;
                  bottom: 0;
                  height: 1rem;
                  width: 100%;
                  background: #2a2931;
                  position: absolute;
                }
                .img-desktop-fluid{
                  display: none;
                }
                .img-mobile-fluid{
                  display: block;
                }
                

              /* content */
                main {
                  padding: 3rem 0rem;
                }
                main .container {
                  padding: 0px 20px;
                  margin-right: auto;
                  margin-left: auto;
                  display: block;
                }
                main .container ol.breadcrumb{
                  border-bottom: none;
                  background: transparent;
                  display: flex;
                  flex-wrap: wrap;
                  padding: 0.75rem 1rem;
                  margin-bottom: 1rem;
                  list-style: none;
                  display: none;
                }
                main section.py-main.py-main{
                  padding: 3.5rem 0rem;
                }
                main section.py-main.py-main .container.mw-md{
                  max-width: 100%;
                }
                main section.py-main.py-main .container.mw-md .post-header .post-category{
                  text-transform: uppercase;
                }
                main section.py-main.py-main .container.mw-md .post-header .post-category, 
                main section.py-main.py-main .container.mw-md .post-header .post-date{
                  display: inline-block;
                  font-size: .8rem;
                  color: #9ca7b2;
                  padding-bottom: 1rem;
                }
                main section.py-main.py-main .container.mw-md .post-header h1{
                  font-size: calc(1.035rem + 1.62vw);
                  font-weight: bolder;
                  margin-bottom: 1rem;
                  line-height: 1.2;
                  color: #1e2d80;
                  margin-top: 0rem;
                }
                main section.py-main.py-main .container.mw-md .social-links{
                  display: block;
                  margin: 0 0 0 -0.125rem;
                  padding-left: 0px;
                }
                main section.py-main.py-main .container.mw-md .social-links .social-item{
                  margin-right: 0.55rem;
                  display: inline-block;
                  list-style: none;
                }
                main section.py-main.py-main .container.mw-md .social-links .social-item amp-social-share{
                  background-color: #333;
                }
                main section.py-main.py-main .container.discover-article-wrapper h2 {
                  margin-bottom: 1.5rem;
                  font-size: calc(.99rem + 1.08vw);
                  font-weight: 700;
                  line-height: 1.2;
                  color: #1e2d80;
                  margin-top: 0px;
                }
                main section.py-main.py-main .container.discover-article-wrapper .row{
                  display: block;
                  max-width: 100%;
                  margin-right: -10px;
                  margin-left: -10px;
                }
                main section.py-main.py-main .container.discover-article-wrapper .row .col-12.col-md{
                  flex-basis: 0;
                  flex-grow: 1;
                  max-width: 100%;
                  position: relative;
                  width: 100%;
                  padding: 0px 20px;
                }
                main section.py-main.py-main .container.discover-article-wrapper .row .col-12.col-md .card{
                  position: relative;
                  display: flex;
                  flex-direction: column;
                  min-width: 0;
                  word-wrap: break-word;
                }
                main section.py-main.py-main .container.discover-article-wrapper .row .col-12.col-md .card.card-boxless{
                  background: transparent;
                  border: none;
                  box-shadow: none;
                  margin-top: 1rem;
                }
                main section.py-main.py-main .container.discover-article-wrapper .row .col-12.col-md .card.card-boxless .card-bg-img{
                  height: 210px;
                  margin: 5px;
                }
                main section.py-main.py-main .container.discover-article-wrapper .row .col-12.col-md .card.card-boxless .card-body{
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  flex: 1 1 auto;
                  min-height: 1px;
                  padding: 1rem 0 0;
                }
                main section.py-main.py-main .container.discover-article-wrapper .row .col-12.col-md .card.card-boxless .card-body .card-label{
                  color: #9ca7b2;
                  font-size: .75rem;
                  letter-spacing: .25rem;
                  margin-bottom: 0.5rem;
                }
                main section.py-main.py-main .container.discover-article-wrapper .row .col-12.col-md .card.card-boxless .card-body .card-title{
                  margin-bottom: 0.5rem;
                  color: #333;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  margin-top: 0px;
                  font-size: 1.575rem;
                  font-weight: 700;
                  line-height: 1.2;
                }
                main section.py-main.py-main .container.discover-article-wrapper .row .col-12.col-md .card.card-boxless .card-body .card-text{
                  color: #9ca7b2;
                  display: -webkit-box;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                }
                main section.py-main.py-main .container.discover-article-wrapper .row .col-12.col-md .card.card-boxless .card-body .card-text:last-child{
                  margin-bottom: 0;
                }

            }
            @media (min-width: 992px) and (max-width: 1199.98px) {

            }
            @media (min-width: 1200px) {
              /* general */
                .m-0 {
                  margin: 0px;
                }
                .pb-3 {
                  padding-bottom: 1rem;
                }
                a{
                  color: #f79226;
                  background-color: transparent;
                  text-decoration: none;
                }
                .amp-sidebar-mask{
                  z-index: 1020;
                }
                .row-0 {
                  margin-left: 0;
                  margin-right: 0;
                }
                .row-0 .col-12{
                  padding-right: 0;
                  padding-left: 0;
                }

              /* header */
                .navbar-wrapper.fixed-top {
                  position: fixed;
                  right: 0;
                  left: 0;
                  top: 0;
                  z-index: 1030;
                  width: 100%;
                }
                .navbar-wrapper.fixed-top header{
                  position: fixed;
                  width: 100%;
                }
                .navbar-wrapper.fixed-top header .navbar.navbar-expand-lg{
                  height: 60px;
                  max-width: 100%;
                }
                .navbar-wrapper.fixed-top header .navbar.navbar-expand-lg ul{
                  padding: 0.5rem 6.5rem;
                  max-width: 100%;
                }
                .navbar-wrapper.fixed-top header .navbar.navbar-expand-lg ul li.navbar-toggler,
                .navbar-wrapper.fixed-top header .navbar.navbar-expand-lg ul li.login-toggler{
                  float: right;
                }
                .navbar-wrapper.fixed-top header .navbar.navbar-expand-lg ul li .btn-login{
                  text-decoration: none;
                  right: 50px;
                  padding: 5px 15px;
                  margin-top: 10px;
                  color: #fff;
                  background-color: #1e2d80;
                  border-color: #1e2d80;
                  display: inline;
                  text-align: center;
                  font-weight: 400;
                  vertical-align: middle;
                  margin-right: 5px;
                }
                .navbar-wrapper.fixed-top amp-sidebar{
                  width: 100%;
                  flex-direction: column;
                  background-color: #fff;
                  max-width: 40vw;
                }
                .navbar-wrapper.fixed-top amp-sidebar .close-sidebar-right{
                  padding: 15px;
                  text-align: right;
                  cursor: pointer;
                }
                .navbar-wrapper.fixed-top amp-sidebar .close-sidebar-left{
                  padding: 15px;
                  text-align: left;
                  cursor: pointer;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.language-wrapper {
                  float: right;
                  margin-top: 0px;
                  padding-left: 0px;
                  padding-right: 20px;
                  list-style: none;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.social-links {
                  position: relative;
                  display: flex;
                  padding-left: 20px;
                  width: 50%;
                  float: left;
                  list-style: none;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.social-links li{
                  float: left;
                  width: 40px;
                  margin-right: 5px;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.social-links li a.footer-youtube-icon{
                  height: 35px;
                  width: 35px;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.social-links li a{
                  color: #1e2d80;
                  height: 30px;
                  width: 30px;
                  display: flex;
                  align-items: center;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.sidebar,
                .navbar-wrapper.fixed-top amp-sidebar ul.sidebar-additional {
                  padding: 15px;
                  list-style-type: none;
                  display: flex;
                  flex-direction: column;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.sidebar-additional li{
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                  left: 0;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.sidebar li a,
                .navbar-wrapper.fixed-top amp-sidebar ul.sidebar li .sub-sidebar {
                  margin-bottom: 0.5rem;
                  line-height: 1.2;
                  cursor: pointer;
                  display: inline-block;
                  text-decoration: none;
                  padding: 0.5rem 0rem;
                  font-size: 1.35rem;
                  font-weight: 600;
                  color: #1e2d80;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.sidebar li.social-links-wrapper{
                  display: flex;
                  justify-content: space-between;
                  width: 100%;
                  left: 0;
                  padding-left: 1rem;
                  padding-right: 1rem;
                  bottom: 2rem;
                  overflow: hidden;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.sidebar li.social-links-wrapper ul.social-links{
                  display: block;
                  margin: 0 0 0 -0.125rem;
                  margin-top: 1rem;
                  padding-left: 0;
                  list-style: none;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.sidebar li.social-links-wrapper ul.social-links li.social-item{
                  margin-right: 0.55rem;
                  display: inline-block;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.navbar-nav-developments,
                .navbar-wrapper.fixed-top amp-sidebar ul.navbar-nav-about,
                .navbar-wrapper.fixed-top amp-sidebar ul.navbar-nav-services{
                  display: block;
                  padding-left: 20px;
                  padding-right: 20px;
                  flex-wrap: wrap;
                  align-items: center;
                  justify-content: space-between;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.navbar-nav-developments li {
                  list-style: none;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.navbar-nav-developments li h2 {
                  margin-bottom: 0.5rem;
                  font-weight: 700;
                  line-height: 1.2;
                  color: #1e2d80;
                  margin-top: 0px;
                  padding-left: 0px;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.navbar-nav-developments li span {
                  margin-top: 0;
                  margin-bottom: 1rem;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.navbar-nav-developments li.nav-item {
                  flex-direction: column;
                  display: flex;
                  padding-left: 0;
                  margin-bottom: 1rem;
                  list-style: none;
                  overflow: hidden;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.submenu-list-card{
                  padding: 0px 20px;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.navbar-nav-about li,
                .navbar-wrapper.fixed-top amp-sidebar ul.navbar-nav-services li {
                  list-style: none;
                  padding: 10px 0px;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.navbar-nav-developments li.nav-item a.nav-link,
                .navbar-wrapper.fixed-top amp-sidebar ul.navbar-nav-about li a.nav-link,
                .navbar-wrapper.fixed-top amp-sidebar ul.navbar-nav-services li a.nav-link {
                  font-size: .9rem;
                  padding-left: 0;
                  padding-right: 0;
                  color: #1e2d80;
                  font-weight: 700;
                  position: relative;
                }
                .navbar-wrapper.fixed-top amp-sidebar h5{
                  color: #000001;
                  padding-left: 20px;
                  padding-right: 20px;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.about-submenu {
                  list-style: none;
                  padding-inline-start: 40px;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.about-submenu li.about-submenu-item {
                  font-size: 1rem;
                  padding: 0.75rem 0;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.about-submenu li.about-submenu-item a {
                  color: #677685;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.contact-us-wrapper {
                  list-style: none;
                  padding: 0px;
                }
                .navbar-wrapper.fixed-top amp-sidebar ul.contact-us-wrapper li{
                  list-style: none;
                  padding: 0px 20px;
                }
                .navbar-wrapper.fixed-top amp-sidebar button.btn-secondary,
                .navbar-wrapper.fixed-top amp-sidebar ul.navbar-nav-services li button.btn-secondary,
                .navbar-wrapper.fixed-top amp-sidebar ul.sidebar li a.btn-secondary,
                .navbar-wrapper.fixed-top amp-sidebar ul.sidebar-additional li a.btn-secondary{
                  cursor: pointer;
                  text-transform: capitalize;
                  display: block;
                  width: 100%;
                  color: #fff;
                  background-color: #1e2d80;
                  border-color: #1e2d80;
                  box-shadow: inset 0 1px 0 hsl(0deg 0% 100% / 15%), 0 1px 1px rgb(0 0 1 / 8%);
                  font-weight: 400;
                  text-align: center;
                  vertical-align: middle;
                  border: 1px solid transparent;
                  padding: 0.75rem 1.5rem;
                  font-size: .9rem;
                  line-height: 1.5;
                  border-radius: 0;
                  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                }
                .navbar-wrapper.fixed-top amp-sidebar h2 {
                  margin-bottom: 0.5rem;
                  font-weight: 700;
                  line-height: 1.2;
                  color: #1e2d80;
                  margin-top: 0px;
                  padding: 0px 20px;
                }

              /* footer */
                footer{
                  display: block;
                  background: #f3f3f3;
                }
                footer .footer-top.py-main.pb-4{
                  position: relative;
                  padding-top: 3.5rem;
                  padding-bottom: 1.5rem;
                }
                footer .footer-top.py-main.pb-4 .container{
                  padding: 0px 20px;
                  max-width: 1140px;
                  margin-right: auto;
                  margin-left: auto;
                }
                footer .footer-top.py-main.pb-4 .container .row{
                  display: flex;
                  width: 100%;
                  margin-right: -10px;
                  margin-left: -10px;
                }
                footer .footer-top.py-main.pb-4 .container .row .col-md-4.col-12.footer-col.mb-3{
                  margin-bottom: 1rem;
                  flex: 0 0 33.33333%;
                  max-width: 33.33333%;
                  postion: relative;
                  width: 100%;
                  padding: 0px 10px;
                }
                footer .footer-top.py-main.pb-4 .container .row .col-12{
                  flex: 0 0 100%;
                  max-width: 100%;
                  position: relative;
                  width: 100%;
                  padding: 0px 10px;
                }
                footer .footer-top .footer-col h5{
                  font-size: 1.125rem;
                  font-weight: 700;
                  text-transform: uppercase;
                  margin-bottom: 1.25rem;
                  line-height: 1.2;
                  color: #1e2d80;
                }
                footer .footer-top.py-main.pb-4 .container .row .col-md-2.col-12.mb-3{
                  margin-bottom: 1rem;
                  flex: 0 0 16.66667%;
                  max-width: 16.66667%;
                  position: relative;
                  width: 100%;
                  padding: 0px 10px;
                }
                footer .footer-top.py-main.pb-4 .container .row .col-md-2.col-12.mb-3 ul.footer-nav{
                  list-style: none;
                  padding-left: 1px;
                }
                footer .footer-top.py-main.pb-4 .container .row .col-md-2.col-12.mb-3 ul.footer-nav li.footer-item{ 
                  padding: 10px 0px;
                }
                footer .footer-top.py-main.pb-4 .container .row .col-md-4.col-12.mb-3{
                  margin-bottom: 1rem;
                  flex: 0 0 33.33333%;
                  max-width: 33.33333%;
                  position: relative;
                  width: 100%;
                  padding: 0px 10px;
                }
                footer .footer-top.py-main.pb-4 .container .row .col-md-4.col-12.mb-3 .footer-col.footer-newsletter ul.social-links.mt-3{
                  display: block;
                  margin: 0 0 0 -0.125rem;
                  margin-top: 1rem;
                  padding-left: 0;
                  list-style: none;
                }
                footer .footer-top.py-main.pb-4 .container .row .col-md-4.col-12.mb-3 .footer-col.footer-newsletter ul.social-links.mt-3 .social-item{
                  margin-right: 0.55rem;
                  display: inline-block;
                }
                footer .footer-top.py-main.pb-4 .container .row .col-md-4.col-12.mb-3 .footer-col.footer-newsletter ul.social-links.mt-3 .social-item .social-link{
                  height: 25px;
                  width: 25px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: #1e2d80;
                }
                footer .footer-top.py-main.pb-4 .container .row .col-md-4.col-12.mb-3 .footer-col.footer-newsletter ul.social-links.mt-3 .social-item .social-link.footer-youtube-icon{
                  height: 30px;
                  width: 35px;
                }
                footer .footer-top.py-main.pb-4 .container .row .col-md-4.col-12.mb-3 .footer-col.footer-newsletter ul.social-links.mt-3 .social-item .social-link.footer-tiktok-icon{
                  height: 22px;
                  width: 22px;
                }
                footer .footer-top.py-main.pb-4 .container .row .col-md-4.col-12.mb-3 .footer-col.footer-newsletter .footer-copyright.d-none.d-md-block{
                  margin-top: 1.5rem;
                  font-size: .7875rem;
                  display: block;
                }
                footer .footer-top.py-main.pb-4 .container .row .col-md-4.col-12.mb-3 .footer-col.footer-newsletter .footer-copyright.d-none.d-md-block a{
                  color: #333;
                  font-weight: 600;
                }
                
                footer .footer-top.py-main.pb-4 .container .row .col-md-12 .row .col-md-4 .footer-copyright.d-block.d-md-none{
                  display: none;
                }
                footer .red-ribbon{
                  display: flex;
                  justify-content: flex-end;
                  position: relative;
                  margin-top: -16px;
                  width: 100%;
                }
                footer .red-ribbon amp-img{
                  z-index: 3;
                }
                footer .red-ribbon .ribbon {
                  height: 68px;
                  position: absolute;
                  bottom: 0;
                }
                footer .red-ribbon .ribbon.red {
                  z-index: 1;
                  left: 0;
                  background: #dd1b20;
                  height: 70px;
                  padding: 7px 0px;
                  width: calc(100% - 247px);
                }
                footer .red-ribbon .ribbon.black {
                  width: 247px;
                  right: 0;
                  z-index: 2;
                  bottom: 0;
                  background: #2a2931;
                }
                footer .red-ribbon .ribbon.black-bottom{
                  z-index: 1;
                  left: 0;
                  bottom: 0;
                  height: 1rem;
                  width: 100%;
                  background: #2a2931;
                  position: absolute;
                }
                .img-desktop-fluid{
                  display: block;
                  margin-bottom: 16px;
                }
                .img-mobile-fluid{
                  display: none;
                }

              /* content */
                main {
                  padding: 3rem 0rem;
                }
                main .container {
                  padding: 0px 20px;
                  max-width: 1140px;
                  width: 100%;
                  margin-right: auto;
                  margin-left: auto;
                }
                main .container ol.breadcrumb{
                  border-bottom: none;
                  background: transparent;
                  display: flex;
                  flex-wrap: wrap;
                  padding: 0.75rem 1rem;
                  margin-bottom: 1rem;
                  list-style: none;
                }
                main .container ol.breadcrumb li.breadcrumb-item{
                  font-size: .7875rem;
                  display: flex;
                }
                main .container ol.breadcrumb li.breadcrumb-item a{
                  color: #f79226;
                  background-color: transparent;
                  position: relative;
                  transition: all .2s cubic-bezier(.55,.085,.68,.53);
                }
                main .container ol.breadcrumb li.breadcrumb-item+li.breadcrumb-item:before{
                  padding-left: 0.5rem;
                }
                main .container ol.breadcrumb li.breadcrumb-item+li.breadcrumb-item:before{
                  display: inline-block;
                  padding-right: 0.5rem;
                  color: #677685;
                  content: "/";
                }
                main section.py-main.py-main{
                  padding: 3.5rem 0rem;
                }
                main section.py-main.py-main .container.mw-md{
                  max-width: 720px;
                }
                main section.py-main.py-main .container.mw-md .post-header .post-category{
                  text-transform: uppercase;
                }
                main section.py-main.py-main .container.mw-md .post-header .post-category, 
                main section.py-main.py-main .container.mw-md .post-header .post-date{
                  display: inline-block;
                  font-size: .8rem;
                  color: #9ca7b2;
                }
                main section.py-main.py-main .container.mw-md .post-header h1{
                  font-size: 2.25rem;
                  font-weight: bolder;
                  margin-bottom: 1rem;
                  line-height: 1.2;
                  color: #1e2d80;
                  margin-top: 0rem;
                }
                main section.py-main.py-main .container.mw-md .social-links{
                  display: block;
                  margin: 0 0 0 -0.125rem;
                  padding-left: 0px;
                }
                main section.py-main.py-main .container.mw-md .social-links .social-item{
                  margin-right: 0.55rem;
                  display: inline-block;
                  list-style: none;
                }
                main section.py-main.py-main .container.mw-md .social-links .social-item amp-social-share{
                  background-color: #333;
                }
                main section.py-main.py-main .container.discover-article-wrapper h2 {
                  margin-bottom: 1.5rem;
                  font-size: 1.8rem;
                  font-weight: 700;
                  line-height: 1.2;
                  color: #1e2d80;
                  margin-top: 0px;
                }
                main section.py-main.py-main .container.discover-article-wrapper .row{
                  display: flex;
                  flex-wrap: wrap;
                  margin-right: -10px;
                  margin-left: -10px;
                }
                main section.py-main.py-main .container.discover-article-wrapper .row .col-12.col-md{
                  flex-basis: 0;
                  flex-grow: 1;
                  max-width: 100%;
                  position: relative;
                  width: 100%;
                  padding: 0px 20px;
                }
                main section.py-main.py-main .container.discover-article-wrapper .row .col-12.col-md .card{
                  position: relative;
                  display: flex;
                  flex-direction: column;
                  min-width: 0;
                  word-wrap: break-word;
                }
                main section.py-main.py-main .container.discover-article-wrapper .row .col-12.col-md .card.card-boxless{
                  background: transparent;
                  border: none;
                  box-shadow: none;
                  margin-top: 1rem;
                }
                main section.py-main.py-main .container.discover-article-wrapper .row .col-12.col-md .card.card-boxless .card-body{
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  flex: 1 1 auto;
                  min-height: 1px;
                  padding: 1rem 0 0;
                }
                main section.py-main.py-main .container.discover-article-wrapper .row .col-12.col-md .card.card-boxless .card-body .card-label{
                  color: #9ca7b2;
                  font-size: .75rem;
                  letter-spacing: .25rem;
                  margin-bottom: 0.5rem;
                }
                main section.py-main.py-main .container.discover-article-wrapper .row .col-12.col-md .card.card-boxless .card-body .card-title{
                  margin-bottom: 0.5rem;
                  color: #333;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  margin-top: 0px;
                  font-size: 1.575rem;
                  font-weight: 700;
                  line-height: 1.2;
                }
                main section.py-main.py-main .container.discover-article-wrapper .row .col-12.col-md .card.card-boxless .card-body .card-text{
                  color: #9ca7b2;
                  display: -webkit-box;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                }
                main section.py-main.py-main .container.discover-article-wrapper .row .col-12.col-md .card.card-boxless .card-body .card-text:last-child{
                  margin-bottom: 0;
                }
            }
          `}
        </style>
        <script async src="https://cdn.ampproject.org/v0.js"></script>
        <script async custom-element="amp-form" src="https://cdn.ampproject.org/v0/amp-form-0.1.js"></script>
        <script async custom-element="amp-mega-menu" src="https://cdn.ampproject.org/v0/amp-mega-menu-0.1.js"></script>
        <script async custom-element="amp-sidebar" src="https://cdn.ampproject.org/v0/amp-sidebar-0.1.js"></script>
        <script async custom-element="amp-social-share" src="https://cdn.ampproject.org/v0/amp-social-share-0.1.js"></script>
        <script async custom-element="amp-analytics" src="https://cdn.ampproject.org/v0/amp-analytics-0.1.js"></script>
      </Helmet>
      <amp-analytics type="gtag" data-credentials="include">
        <script
          type="application/json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(gtagParam)
          }}
        ></script>
      </amp-analytics>
      <SEO
        title={seo.seo.title}
        desc={seo.seo.opengraphDescription}
        fbImg={seo.seo.opengraphImage?.sourceUrl || SEOFeaturedImg}
        fbTitle={seo.seo.opengraphTitle}
        fbDesc={seo.seo.opengraphDescription}
        url={redirectAmpHtml.replace('/news-events/amp/', '/news-events/')}
        twitterTitle={seo.seo.twitterTitle}
        twitterDesc={seo.seo.twitterDescription || seo.seo.opengraphDescription}
        twitterImg={seo.seo.twitterImage?.sourceUrl || SEOFeaturedImg}
        type="article"
      />

      <Container>
        <Breadcrumb path={location.pathname.replace('/news-events/amp/', '/news-events/')} className="d-none d-md-flex" />
      </Container>

      <Section>
        <Container className="mw-md">
          <PostHeader
            title={
              lang == "EN"
                ? replaceUnicode(pageContext.data.title)
                : lang == "ID" && pageContext.data.translation !== null
                  ? replaceUnicode(pageContext.data.translation.title)
                  : lang == "ID" && pageContext.data.translation === null
                    ? replaceUnicode(pageContext.data.title)
                    : replaceUnicode(pageContext.data.title)
              // pageContext.data.title
              //   ? replaceUnicode(pageContext.data.title)
              //   : "Title"
            }
            category={
              pageContext.data.categories.nodes[0] &&
              pageContext.data.categories.nodes[0].name
            }
            date={getDate(pageContext.data.date, lang)}
          />
          <SocialShare className="pb-3" link={location.href} onClick={handleCopy} isAmp={true}/>
        </Container>

        {coverMain && coverMain.coverMain.img !== null && (
          <Cover
            variant="fluid"
            theme="dark"
            img={coverMain.coverMain.img.mediaItemUrl}
            // img={coverMain.coverMain.img.mediaItemUrl}
            imgHeight="h-500px w-100"
            className="pt-3 pb-main"
          />
        )}
        <Container className="mw-md mb-5">
          {wp.map((block, i) => {
            switch (block.name) {
              case "core/heading":
                return <CoreHeading data={block} key={`${block.name}${i}`} />
              case "core/paragraph":
                return <CoreParagraph data={block} key={`${block.name}${i}`} />
              case "core/image":
                const imageURL = block.attributes.url.replace(process.env.ROOT_ENV, location.origin)
                return (
                  <div className="mb-4">
                    <amp-img
                      alt="news image"
                      src={imageURL}
                      class="img-content"
                      width="150"
                      height="100"
                      layout="responsive"
                      heights="(min-width: 300px) 65%"
                      sizes="(max-width: 400px) 90vw, (max-width: 960px) 100%, 720px"
                    ></amp-img>
                    {/* {isAmp? (                    
                      <amp-img
                        alt="news image"
                        src={imageURL}
                        class="img-content"
                        width="150"
                        height="100"
                        layout="responsive"
                        heights="(min-width: 300px) 80%"
                        sizes="(max-width: 400px) 90vw, (max-width: 960px) 100%, 720px"
                      ></amp-img>
                    ):(
                      <img
                        alt="news image"
                        src={imageURL}
                        // src={block.attributes.url}
                        className="img-fluid"
                        key={`${block.name}${i}`}
                      />
                    )} */}
                    <p 
                      key={`caption-${i}`} 
                      className="text-center m-0" 
                      dangerouslySetInnerHTML={{ __html: block?.attributes?.caption}} 
                      style={{ fontSize: "16px"}}
                    >
                    </p>
                  </div>
                )
              case "acf/acfimage":
                return (
                  <div className="mb-4">
                    <amp-img
                      alt="news image"
                      src={block.imageBlock.img.mediaItemUrl}
                      class="img-content"
                      width="150"
                      height="100"
                      layout="responsive"
                      heights="(min-width: 300px) 65%"
                      sizes="(max-width: 400px) 90vw, (max-width: 960px) 100%, 720px"
                      // key={`${block.name}${i}`}
                    ></amp-img>
                    {/* {isAmp ? (
                      <amp-img
                        alt="news image"
                        src={block.imageBlock.img.mediaItemUrl}
                        class="img-content"
                        width="150"
                        height="100"
                        layout="responsive"
                        heights="(min-width: 300px) 80%"
                        sizes="(max-width: 400px) 90vw, (max-width: 960px) 100%, 720px"
                        // key={`${block.name}${i}`}
                      ></amp-img>
                    ) : (
                      <img
                        alt="news image"
                        src={
                          block.imageBlock.img.mediaItemUrl
                        }
                        className="img-fluid"
                        key={`${block.name}${i}`}
                      />
                    )} */}
                    <ReactMarkdown
                      source={block?.imageBlock?.img?.caption}
                      escapeHtml={false}
                      key={`caption-${i}`}
                      className="text-center m-0 font-italic"
                      style={{ fontSize: "16px" }}
                    />
                  </div>

                )
              case "core/list":
                return (
                  <ReactMarkdown
                    source={block.attributes.values}
                    escapeHtml={false}
                    key={`${block.name}${i}`}
                    className="px-3 pb-3"
                  />
                )
              default:
                break
            }
          })}
          {/* <p className="text-muted font-weight-bold">
            {pageContext.data.tags.nodes.map((item, i) => {
              if (i === pageContext.data.tags.nodes.length - 1) {
                return ` ${item.name}`
              }
              return `${item.name},`
            })}
          </p> */}
        </Container>
        <Container className="discover-article-wrapper">
          {wp.map((block, i) => {
            switch (block.name) {
              case "acf/acfgallery":
                return (
                  <AcfGallery
                    className="py-main"
                    data={block}
                    key={`${block.name}${i}`}
                  />
                )
              default:
                break
            }
          })}
          <h2 className="mb-4">
            {lang == "EN"
              ? "Discover more articles like this"
              : "Lihat Artikel Yang lain"}
          </h2>
          <div className="row">
            {recommendedNews.length > 0 &&
              recommendedNews.map((post, i) => {
                const postLang = pageContext.langKey === "id"
                  ? post.translation || post
                  : post || post.translation
                if (recommendedNews.length > 1) {
                  if (i <= 3) {
                    return (
                      <div
                        className="col-12 col-md"
                        key={`recommendedNews-${postLang.title}-${i}`}
                      >
                        <CardAmp
                          className="card-recommended-news mt-3"
                          variant="boxless"
                          colLeft="col-12"
                          colRight="col-12"
                          label={`${postLang.categories.nodes[0].name} - ${getDate(postLang.date, lang, { time: false })}`}
                          title={postLang.title}
                          text={
                            postLang.excerpt
                              ? postLang.excerpt
                              : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                          }
                          img={
                            postLang.featuredImage
                              ? postLang.featuredImage.sourceUrl
                              : "https://beryl-ks79.antikode.dev/app/uploads/2020/08/img-thumbnail-wellness-1024x570.jpg"
                          }
                          imgHeight="h-ratio-3-2"
                          link={`/news-events/${post.slug}`}
                        />
                      </div>
                    )
                  }
                } else if (recommendedNews.length === 1) {
                  return (
                    <div
                      className="col-12 col-md-6"
                      key={`recommendedNews-${postLang.title}-${i}`}
                    >
                      <CardAmp
                        className="card-recommended-news mt-3"
                        labelClassName="card-label-uppercase"
                        variant="boxless"
                        colLeft="col-12"
                        colRight="col-12"
                        label={`${(postLang.categories.nodes.length > 0 &&
                          postLang.categories.nodes[0].name.toUpperCase()) ||
                          ""} - ${getDate(postLang.date, lang, { time: false })}`}
                        title={
                          (postLang.title && postLang.title.length > 40
                            ? `${postLang.title.substring(0, 40)}...`
                            : postLang.title) || "Title"
                        }
                        text={
                          postLang.excerpt
                            ? postLang.excerpt
                            : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
                        }
                        img={
                          postLang.featuredImage
                            ? postLang.featuredImage.sourceUrl
                            : "https://beryl-ks79.antikode.dev/app/uploads/2020/08/img-thumbnail-wellness-1024x570.jpg"
                        }
                        imgHeight="h-ratio-3-2"
                        link={`/news-events/${post.slug}`}
                      />
                    </div>
                  )
                }
              })}
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

export default NewsAmpDetails