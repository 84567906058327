import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

export const Breadcrumb = ({ path, theme, className }) => {
  const pathNames = (path && path.split("/")) || [];

  return (
    <ol className={`breadcrumb ${theme} ${className}`}>
      {pathNames.length > 0 &&
        pathNames.map((item, index) => {
          if (index === 0) {
            return (
              <li className="breadcrumb-item" key={`path-home`}>
                <Link to="/">Home</Link>
              </li>
            );
          } else if (index === pathNames.length - 1 && item !== "") {
            return (
              <li className="breadcrumb-item" key={`path-${item}`}>
                {item.split("-").join(" ")}
              </li>
            );
          } else if (item === "") {
            return null;
          } else {
            let url = pathNames.slice(0, index + 1).join("/");
            return (
              <li className="breadcrumb-item" key={`path-${item}`}>
                <Link to={url}>{item.split("-").join(" ")}</Link>
              </li>
            );
          }
        })}
    </ol>
  );
};

Breadcrumb.propTypes = {
  path: PropTypes.string,
  theme: PropTypes.oneOf(["", "light", "dark"]), // Set cover theme
  className: PropTypes.string,
};

Breadcrumb.defaultProps = {
  theme: "",
  className: "",
};
